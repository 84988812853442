import React from "react";

import { Box, SimpleGrid } from "@chakra-ui/react";

import { graphql } from "gatsby";

import DesktopVid from "../../video/lapin-desktop.mp4";
import MobileVid from "../../video/lapin-mobile.mp4";
import WineVid from "../../video/red-wine-lores.mp4";
import { GatsbyImage, getImage, } from "gatsby-plugin-image";
import PanImg from "../../components/animations/PanImg";
import ProjectHeader from "../../components/Header/ProjectHeader";
import { mypx, xgap } from "../../constants/spacing";
import SilentPlayer from "../../components/video/SilentPlayer";
import PCM from "../../components/cards/ProjectCardMotion";
import SEO from "../../components/SEO";
import ProjectArrows, { ProjectLinkers } from "../../components/Header/ProjectArrows";

const preventClick = (e) => e.preventDefault()
const LeLapin = ({ data, location }) => {
  const dat = data.wpProject.hollandProjects;
  console.log(dat);
  
  const MobileVideo = dat.headerVideoMobile.localFile.publicURL
  const DesktopVideo = dat.headerVideoDesktop.localFile.publicURL


  const img1 = getImage(dat.image1.localFile);
  const img2 = getImage(dat.image2.localFile);
  const img3 = getImage(dat.image3.localFile);
  const img4 = getImage(dat.image4.localFile);
  const img5 = getImage(dat.image5.localFile);
  const img6 = getImage(dat.image6.localFile);
  const img7 = getImage(dat.image7.localFile);
  const img8 = getImage(dat.image8.localFile);
  const img9 = getImage(dat.image9.localFile);
  const img10 = getImage(dat.image10.localFile);
  const img11 = getImage(dat.image11.localFile);
  const img12 = getImage(dat.image12.localFile);
  const img13 = getImage(dat.image13.localFile);
  const img14 = getImage(dat.image14.localFile);
  const img15 = getImage(dat.image15.localFile);
  const img16 = getImage(dat.image16.localFile);
  const img17 = getImage(dat.image17.localFile);
  const img18 = getImage(dat.image18.localFile);

  return (
    <>
    <SEO title={dat.projecttitle} description={dat.projectdescription} image={img1} url="https://hollandhames.com/project/le-lapin"/>
      <ProjectHeader
        mobileVid={MobileVideo}
        desktopVid={DesktopVideo}
        title={dat.projecttitle}
        description={dat.projectdescription}
      />

      <SimpleGrid pb={xgap} columns={1} px={mypx} gap={xgap}>
        <SimpleGrid columns={1} gap={xgap}>
          <GatsbyImage onContextMenu={preventClick} image={img1} />
          {/* <StaticImage src='../../images/le-lapin/img1.png' /> */}
        </SimpleGrid>
        <SimpleGrid gap={xgap} columns={[1, null, null, null, 2]}>
          <PCM image={img2} />
          <PCM image={img3} />
        </SimpleGrid>

        <SimpleGrid gap={xgap} columns={[1, null, null, null, 2]}>
          <SimpleGrid gap={xgap} columns={1}>
            <GatsbyImage onContextMenu={preventClick} image={img4} />
            <GatsbyImage onContextMenu={preventClick} image={img6} />
          </SimpleGrid>
          <SimpleGrid gap={xgap} columns={1}>
            <GatsbyImage onContextMenu={preventClick} image={img5} />
            <SilentPlayer url={WineVid} />
          </SimpleGrid>
        </SimpleGrid>
        <SimpleGrid columns={1} gap={xgap}>
          <GatsbyImage onContextMenu={preventClick} image={img7} />
        </SimpleGrid>
        <SimpleGrid gap={xgap} columns={[1, null, null, null, 2]}>
          <SimpleGrid gap={xgap} columns={1}>
            <GatsbyImage onContextMenu={preventClick} image={img8} />
            <GatsbyImage onContextMenu={preventClick} image={img10} />
          </SimpleGrid>
          <SimpleGrid gap={xgap} columns={1}>
            <GatsbyImage onContextMenu={preventClick} image={img9} />
            {/* <Box mt={["15%", null,null,null,'0%']} /> */}
            <PanImg />
          </SimpleGrid>
        </SimpleGrid>
        <SimpleGrid columns={1}></SimpleGrid>
        <SimpleGrid columns={[1, null, null, null, 2]} gap={xgap}>
          <GatsbyImage onContextMenu={preventClick} image={img12} />
          <GatsbyImage onContextMenu={preventClick} image={img13} />
        </SimpleGrid>
        <GatsbyImage onContextMenu={preventClick} image={img14} />
        <SimpleGrid gap={xgap} columns={[1, null, null, null, 2]}>
          <GatsbyImage onContextMenu={preventClick} image={img15} />
          <GatsbyImage onContextMenu={preventClick} image={img16} />
        </SimpleGrid>
        <SimpleGrid columns={1} gap={xgap}>
          <GatsbyImage onContextMenu={preventClick} image={img17} />
        </SimpleGrid>
        <SimpleGrid columns={[1, null, null, null, 3]} gap={xgap}>
          <Box></Box>
          <GatsbyImage onContextMenu={preventClick} image={img18} />
          <Box></Box>
        </SimpleGrid>
      </SimpleGrid>
      <ProjectLinkers prevTitle='Explore' prevUrl='/explore' nextTitle='Parkhouse' nextUrl='/project/parkhouse' />
    </>
  );
};

export default LeLapin;

export const query = graphql`
  {
    wpProject(title: {eq: "Le Lapin"}) {
      hollandProjects {
        headerVideoDesktop {
          localFile {
            publicURL
          }
        }
        headerVideoMobile {
          localFile {
            publicURL
          }
        }
        projecttitle
        projectdescription
        projectcategories
        image1 {
          localFile {
            childImageSharp {
              gatsbyImageData
            }
          }
        }
        image1 {
          localFile {
            childImageSharp {
              gatsbyImageData
            }
          }
        }
        image2 {
          localFile {
            childImageSharp {
              gatsbyImageData
            }
          }
        }
        image3 {
          localFile {
            childImageSharp {
              gatsbyImageData
            }
          }
        }
        image4 {
          localFile {
            childImageSharp {
              gatsbyImageData
            }
          }
        }
        image5 {
          localFile {
            childImageSharp {
              gatsbyImageData
            }
          }
        }
        image6 {
          localFile {
            childImageSharp {
              gatsbyImageData
            }
          }
        }
        image7 {
          localFile {
            childImageSharp {
              gatsbyImageData
            }
          }
        }
        image8 {
          localFile {
            childImageSharp {
              gatsbyImageData
            }
          }
        }
        image9 {
          localFile {
            childImageSharp {
              gatsbyImageData
            }
          }
        }
        image10 {
          localFile {
            childImageSharp {
              gatsbyImageData
            }
          }
        }
        image11 {
          localFile {
            childImageSharp {
              gatsbyImageData
            }
          }
        }
        image12 {
          localFile {
            childImageSharp {
              gatsbyImageData
            }
          }
        }
        image13 {
          localFile {
            childImageSharp {
              gatsbyImageData
            }
          }
        }
        image14 {
          localFile {
            childImageSharp {
              gatsbyImageData
            }
          }
        }
        image15 {
          localFile {
            childImageSharp {
              gatsbyImageData
            }
          }
        }
        image16 {
          localFile {
            childImageSharp {
              gatsbyImageData
            }
          }
        }
        image17 {
          localFile {
            childImageSharp {
              gatsbyImageData
            }
          }
        }
        image18 {
          localFile {
            childImageSharp {
              gatsbyImageData
            }
          }
        }
      }
    }
  }
`;
