import { StaticImage } from 'gatsby-plugin-image'
import React from 'react'
import {animated, useSpring} from 'react-spring'

import Caro from '../../images/le-lapin/img11-carousel.jpg'

const preventClick = (e) => e.preventDefault()
const PanImg = ({h=2858, w=4000, src='https://picsum.photos/id/1078/4000/2858', t=25, f=40, m=2, image, data  }) => {

    const AnIm = animated(StaticImage)

    const styles = useSpring({
        loop: {reverse:true},
        from:{x:'-25%'},
        to:{x:'0%'},
        config: {mass: `${m}`, tension:`${t}`, friction:`${f}`}
    })

    return(
        <div onContextMenu={preventClick} style={{ overflow:'hidden'}}>
        <animated.div style={{ ...styles, position:'static', width:'200%', overflow:'hidden'}}>
            <animated.img style={{ ...styles,overflow:'hidden', objectFit:'cover'}} src={Caro} />
        </animated.div>
        </div>
    )
}

export default PanImg

